<template>
  <Layout :bgColor="true">
    <div class="down rule-model-bg">
      <div class="rule-width flex">
        <div class="left">
          <div class="flex">
            <img class="img"
                 src="@/assets/img/logo2.png" />
            <div class="font">
              <h4 class="flex"
                  v-for="(item,index) in appList.one"
                  :key="index">
                <span :class="active.id === item.id? 'active':''"
                      @mouseenter="mouseenter(item)">{{item.title}}</span>
              </h4>
            </div>
          </div>
          <div class="flex">
            <img class="img"
                 src="@/assets/img/logo3.png" />
            <div class="font">
              <h4 class="flex"
                  v-for="(item,index) in appList.two"
                  :key="index">
                <span :class="active.id === item.id? 'active':''"
                      @mouseenter="mouseenter(item) ">{{item.title}}</span>
              </h4>
            </div>
          </div>
        </div>
        <div class="right flex flex-align flex-c">
          <div v-if="downFlag"
               class="flex flex-align flex-c animated fadeIn">
            <img :src="active.left"
                 alt="">
            <div class="down-bg"
                 v-if="active.right"
                 :style="`background:url(${downR})  no-repeat 100%/100%`">
              <img :src="active.right"
                   alt="">
            </div>

          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/layout/index'
import animated from '@/utils/animated.js'

import downL1 from '@/assets/img/downL1.png'
import downR1 from '@/assets/img/downR1.png'
import downL2 from '@/assets/img/downL2.png'
import downR2 from '@/assets/img/downR2.png'
import downL3 from '@/assets/img/downL3.png'
import downL4 from '@/assets/img/downL4.png'
import downR4 from '@/assets/img/downR4.png'
import downL5 from '@/assets/img/downL5.png'
import downR5 from '@/assets/img/downR5.png'
import downR from '@/assets/img/downR.png'

export default {
  components: {
    Layout,
  },
  data() {
    return {
      cloudnotesOne: false,
      refArray: [{ ref: 'cloudnotesOne', show: 'cloudnotesOne' }],
      downFlag: true,
      appList: {
        one: [
          { title: '学生端ios版', id: 1, left: downL1, right: downR1 },
          { title: '学生端Android版', id: 2, left: downL2, right: downR2 },
          { title: '老师iPad版', id: 3, left: downL3 },
        ],
        two: [
          { title: 'ios版', id: 4, left: downL4, right: downR4 },
          { title: 'Android版', id: 5, left: downL5, right: downR5 },
        ],
      },
      downR: downR,
      active: {
        title: '学生端ios版',
        id: 1,
        left: downL1,
        right: downR1,
      },
    }
  },
  mixins: [animated],
  methods: {
    mouseenter(item) {
      this.downFlag = false
      this.active = item
      setTimeout(() => {
        this.downFlag = true
      }, 50)
    },
  },
}
</script>

<style lang="scss" scoped>
.down {
  padding-bottom: 60px;
  .left {
    width: 48%;
    height: 100%;
    height: auto;
    margin-top: 120px;
    .img {
      display: block;
      height: 38px;
    }
    .font {
      margin-left: 40px;
      h4 {
        width: 100%;
        margin-bottom: 24px;
        color: #333;
        span {
          cursor: pointer;
          display: block;
          padding: 10px 0;
          border-bottom: 2px solid rgba($color: #000000, $alpha: 0);
          &:hover {
            border-bottom: 2px solid #f6a25d;
            font-weight: bold;
            color: #f6a25d;
          }
          &.active {
            border-bottom: 2px solid #f6a25d;
            color: #f6a25d;
            font-weight: bold;
          }
        }
      }
    }
  }
  .right {
    height: 600px;
    .down-bg {
      width: 280px;
      height: 280px;
      padding: 48px;
      box-sizing: border-box;
      img {
        width: 100%;
        height: 100%;
        min-height: 100px;
        min-width: 100px;
        margin-left: 8px;
      }
    }
  }
}
</style>